exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-authors-page-js": () => import("./../../../src/templates/authors-page.js" /* webpackChunkName: "component---src-templates-authors-page-js" */),
  "component---src-templates-documentaries-page-js": () => import("./../../../src/templates/documentaries-page.js" /* webpackChunkName: "component---src-templates-documentaries-page-js" */),
  "component---src-templates-issue-js": () => import("./../../../src/templates/issue.js" /* webpackChunkName: "component---src-templates-issue-js" */),
  "component---src-templates-issues-page-js": () => import("./../../../src/templates/issues-page.js" /* webpackChunkName: "component---src-templates-issues-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

